<template>
  <b-container>
    <b-row>
      <b-col cols="12">
        <b-card>
          <b-container>
            <b-row>
              <b-col cols="12">
                <v-zxing
                 @decode="onDecode"
                />
              </b-col>
            </b-row>
          </b-container>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import ZxingVue from '../components/ZxingEdit.vue';


export default {
  components: {
    'v-zxing': ZxingVue
  },
  methods: {
    onDecode(result) {
      alert(`Scanner Receive ${result}`)
    }
  }
}
</script>
